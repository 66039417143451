import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import styled, { withTheme } from "styled-components"
import classNames from "classnames"
import { lighten } from "polished"
import SwiperCore, { Autoplay } from "swiper"
import Swiper from "react-id-swiper"
import FeatureTitle from "components/FeatureTitle"
import StickyCta from "components/StickyCta"
import View from "components/View"
import device from "utils/media"
import { underline } from "utils/mixins"
import homeGlow from "../../content/assets/homepage-glow-blue.jpg"
import "swiper/swiper.min.css"

SwiperCore.use([Autoplay])

export const HeroBannerStyled = styled.div`
  background-color: ${props => props.bgColor};
  color: ${props => props.textColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: ${props => (props.hasImages ? "180px 0 60px 0" : "180px 0 140px 0")};
  margin-bottom: 0px;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 200% auto;
  min-height: 100vh;
  overflow: hidden;

  &.data-partners {
    background-image: radial-gradient(
      circle at center bottom,
      ${props => lighten(0.1, props.bgColor)} 0%,
      ${props => props.bgColor} 100%
    );
    @media ${device.md} {
      background-image: radial-gradient(
        circle at 75% center,
        ${props => lighten(0.1, props.bgColor)} 0%,
        ${props => props.bgColor} 25%
      );
    }
  }

  .col {
    position: relative;
    z-index: 0;
    &.has-image {
      z-index: 1;
    }
  }

  .single-image {
    position: relative;
    max-width: 700px;
    margin-top: 0px;
    padding-bottom: 60px;
    @media ${device.md} {
      margin-top: 0;
      padding-bottom: 0;
    }
  }

  &.identity-verification-providers .single-image:after {
    z-index: -1;
    content: "";
    display: block;
    position: absolute;
    top: -40%;
    right: -40%;
    bottom: -40%;
    left: -40%;
    background-image: radial-gradient(
      300px at center,
      #bc2493 0%,
      ${props => props.bgColor} 80%
    );
  }

  &.data-and-insights .single-image {
    position: relative;
    padding-bottom: 20px;
    margin-top: 20px;
    @media ${device.md} {
      margin-top: 0;
      padding-bottom: 0;
    }
  }

  &.home .single-image {
    position: relative;
    margin-top: 30px;
    padding-bottom: 0;
    &:after {
      z-index: -1;
      content: "";
      display: block;
      position: absolute;
      top: -10%;
      right: -10%;
      bottom: -10%;
      left: -10%;
      background-image: url(${homeGlow});
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0.5;
    }

    @media ${device.md} {
      padding-left: 40px;
    }

    img {
      max-width: 100%;
    }
  }

  @media ${device.md} {
    background-position: center right;
    background-size: auto 80%;
    padding: 40px 0 0 0;
    height: 720px;
    .row {
      align-items: center;
    }
  }

  @media ${device.lg} {
    height: 872px;
  }

  @media ${device.xl} {
    height: 972px;
  }

  &.kas-demo {
    min-height: auto;
    height: auto;
    padding: 120px 0 80px 0;
    @media ${device.lg} {
      padding: 90px 0;
    }

    .single-image {
      padding-bottom: 0;
      max-width: 440px;
    }
  }

  &.identity-resolution-providers { 
    padding-bottom: 0px;
    @media ${device.sm} {
      padding-bottom: 30px;
    }
    
    .single-image {
      padding-bottom: 20px;
      max-width: 480px;
      opacity: 1;
      margin-left: auto;
      margin-right: auto;

      &:after {
        z-index: -1;
        content: "";
        display: block;
        position: absolute;
        top: -40%;
        right: -40%;
        bottom: -40%;
        left: -40%;
        background-image: radial-gradient(
          300px at center,
          #63518e 0%,
          ${props => props.bgColor} 80%
        );
      }
    }
  }
`

const HeroBannerImages = styled.div`
  max-width: 540px;
  margin: 0 auto;
  margin-top: 60px;

  @media ${device.md} {
    margin-top: 0;
  }

  .home & {
    max-width: 100%;
    position: relative;
    &:after {
      z-index: -1;
      content: "";
      display: block;
      position: absolute;
      top: -10%;
      right: -10%;
      bottom: -10%;
      left: -10%;
      background-image: url(${homeGlow});
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
`

export const HeadingInner = styled.div`
  position: relative;
  z-index: 10;
`

export const HeadingWrapper = styled.div`
  min-height: 60px;
`

export const BannerHeading = styled(FeatureTitle)`
  color: ${props => props.textColor};
  font-weight: 900;
  line-height: 1.1;
  font-size: ${props => props.theme.text.lg};

  span > span {
    color: ${props => props.theme.color.teal};
  }

  .home & {
    span > span {
      background-color: #ffffff;
      border-radius: 4px;
      padding: 0 6px;
      @media ${device.xl} {
        padding: 0 12px;
      }
    }
  }

  @media ${device.sm} {
    font-size: ${props => props.theme.text.xl};
  }

  @media ${device.md} {
    font-size: ${props => props.theme.text.xl_2};
  }

  @media ${device.xl} {
    font-size: ${props => props.theme.text.xxxl};
  }
`

export const StyledHeroButton = styled(Link)`
  font-style: italic;
  &.btn-home,
  &.btn-home:hover {
    color: #003b51 !important;
  }
`
const BannerText = styled.div``

export const PreHeading = styled.div`
  color: ${props => props.theme.color.teal};
  .publishers & {
    color: #ffffff;
  }
  font-size: ${props => props.theme.text.xs};
  font-style: italic;
  text-transform: uppercase;
  font-weight: 600;
  font-family: ${props => props.theme.font.feature};

  @media ${device.md} {
    font-size: ${props => props.theme.text.sm_2};
  }
`

const SubHeading = styled.h3`
  color: ${props => props.textColor};
  font-size: ${props => props.theme.text.sm_2};
  font-family: ${props => props.theme.font.body};
  line-height: 1.5;
  font-weight: 400;

  @media ${device.md} {
    font-size: ${props => props.theme.text.base_2};
    max-width: 1080px;
  }

  @media ${device.xl} {
    font-size: ${props => props.theme.text.md};
  }

  a {
    ${underline};
    color: ${props => props.theme.color.teal};
  }
`

const HeroBanner = props => {
  const [currentIndex, updateCurrentIndex] = useState(0)
  const [swiper, setSwiper] = useState(null)

  const { fields, pageName, preHeading } = props

  const hasImageSlider =
    fields.include_image_slider && fields.slider_images.length > 1

  const hasSingleImage =
    fields.include_image_slider && fields.slider_images.length === 1

  let slides = null

  const getSlides = items => {
    const slideItems = []
    if (items) {
      Array.from(items).forEach((item, i) => {
        let slide = item

        slideItems.push(
          <div key={i}>
            <img
              className="hero-banner__slide"
              src={slide.url}
              alt={slide.alt}
              loading="eager"
            />
          </div>
        )
      })
    }
    return slideItems
  }

  if (hasImageSlider) {
    slides = getSlides(fields.slider_images)
  }

  const sliderParams = {
    // spaceBetween: 30,
    loop: true,
    // slidesPerView: 1,
    effect: pageName !== "home" ? "fade" : "",
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
  }

  const textColumnClasses = classNames("col", {
    "md-6 has-image":
      (hasImageSlider || hasSingleImage) && pageName !== "kas-demo",
    "md-8 lg-9 has-image":
      (hasImageSlider || hasSingleImage) && pageName === "kas-demo",
    "md-12 lg-10": !hasImageSlider && !hasSingleImage,
  })

  const imageColumnClasses = classNames("col", {
    "md-6": pageName !== "kas-demo",
    "md-4 lg-3": pageName === "kas-demo",
  })

  useEffect(() => {
    if (swiper !== null) {
      swiper.on("slideChange", () => {
        console.log("slide ", currentIndex)
        updateCurrentIndex(swiper.realIndex)
      })
    }

    return () => {
      if (swiper !== null) {
        swiper.off("slideChange", () => updateCurrentIndex(swiper.realIndex))
      }
    }
  }, [swiper, currentIndex])

  return (
    fields &&
    (fields.heading || fields.slides) && (
      <HeroBannerStyled
        bgColor={fields.background_colour}
        textColor={fields.text_colour}
        className={pageName}
        hasImages={hasImageSlider || hasSingleImage}
      >
        <HeadingInner className="container x-large">
          <div className="row">
            <div className={textColumnClasses}>
              {preHeading && <PreHeading>{preHeading}</PreHeading>}
              <HeadingWrapper as={View} threshold={0.5} pageName={pageName}>
                <BannerHeading textColor={fields.text_colour}>
                  <span dangerouslySetInnerHTML={{ __html: fields.heading }} />
                </BannerHeading>
              </HeadingWrapper>
              <BannerText as={View}>
                {fields.subheading && (
                  <SubHeading
                    textColor={fields.text_colour}
                    dangerouslySetInnerHTML={{
                      __html: fields.subheading,
                    }}
                  />
                )}
              </BannerText>
              {pageName === "data-and-insights" && <StickyCta />}
            </div>

            {(hasImageSlider || hasSingleImage) && (
              <div className={imageColumnClasses}>
                {hasImageSlider && (
                  <HeroBannerImages as={View}>
                    <Swiper {...sliderParams} getSwiper={setSwiper}>
                      {slides}
                    </Swiper>
                  </HeroBannerImages>
                )}
                {hasSingleImage && fields.slider_images[0].url && (
                  <div className="single-image">
                    <img
                      className="hero-banner__slide"
                      src={fields.slider_images[0].url}
                      alt={
                        fields.slider_images[0].alt
                          ? fields.slider_images[0].alt
                          : ""
                      }
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </HeadingInner>
      </HeroBannerStyled>
    )
  )
}

export default withTheme(HeroBanner)
