import React, { useRef } from "react"
import { Link } from "gatsby"
import { ReactComponent as UserIcon } from "../../../content/assets/icon-user-white.svg"
import arrowWhite from "images/ArrowWhite.svg"
import useOnScreen from "utils/isVisible"
import { StyledStickyCta } from "./StickyCta.styled"

const StickyCta = () => {
  const stickyRef = useRef()
  const isVisible = useOnScreen(stickyRef)

  return (
    <StyledStickyCta>
      <div className="sticky-cta-wrapper">
        <div ref={stickyRef} className="ref" />
        <Link
          to="/whats-my-kas-segment/"
          title="What's my KAS Segment - Live demo"
        >
          <>
            <button
              className="btn btn-learn_more text-uppercase"
              data-is-visible={isVisible}
            >
              <span className="bannerview">
                What's my KAS Segment?{" "}
                <span className="live-demo">
                  <UserIcon /> Live Demo <img className="arrow" src={arrowWhite} width="10" alt="" />
                </span>
              </span>
              <div className="sideview">
                What's my KAS Segment?
                <span className="live-demo">
                  <UserIcon /> Live Demo <img className="arrow" src={arrowWhite} width="10" alt="" />
                </span>
              </div>
            </button>
          </>
        </Link>
      </div>
    </StyledStickyCta>
  )
}

export default StickyCta
