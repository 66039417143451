import styled from "styled-components"

export const StyledStickyCta = styled.div`
  height: 9rem;
  position: relative;
  pointer-events: none;
  z-index: 200;

  .sticky-cta-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    // z-index: 200;
  }
  button {
    text-align: left;
    font-style: italic;
    pointer-events: all;
    border: none;
    align-items: center;
    cursor: pointer;
    transition: all linear 200ms;
    position: absolute;
    //z-index: 200;
  }
  span.live-demo {
    font-weight: 400;
    font-size: 14px;
    display: flex;
    margin-top: 6px;
    align-items: center;
  }
  svg {
    height: auto;
    width: 16px;
    margin-right: 6px;
  }
  .arrow {
    margin-left: 4px;
    opacity: 0.9;
  }
  [data-is-visible="true"] {
    background-color: #00a7a5;
    //border: 1px solid black;
    top: 0;
    left: 0;
    visibility: visible;
    span.live-demo {
      svg {
        margin-right: 10px;
      }
    }
    .sideview {
      display: none;
      visibility: hidden;
    }
  }
  [data-is-visible="false"] {
    background-color: #2b2b31;
    border: 1px solid black;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: rgb(0 0 0 / 35%) 0px 6px 12px;
    padding: 1.5rem;
    position: fixed;
    left: calc(100% - 15rem);
    top: 80%;
    span.bannerview {
      display: none;
      visibility: hidden;
    }
    .sideview {
      font-size: 14px;
      line-height: 1.2;
      span.live-demo {
        margin-top: 10px;
        svg {
          width: 14px;
        }
      }
    }
  }
`
